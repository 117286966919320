


function WeekContent(){
    return(
        <div id="content">
          <h1>Week For Saturno</h1>
          <br/>
          <span>Bootcamp 100% gratuito</span>
          <br/>
          <br/>
          <br/>
          <p>
            WeeK for Saturno é um bootcamp  100% gratuito e presencial  sobre programação, onde aprenderás durante 5 dias conceitos sobre o Frontend.
            Será uma maratona de 5 dias, para cada município de Luanda.
          </p>
        </div>
    );
}

export default WeekContent;