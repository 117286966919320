import Header from './components/header';
import Newslater from './components/newslater';
import Content from './components/content';
import WeekContent from './components/WeekContent';
import Footer from './components/footer';
import imageProject from '../src/assets/images/1.png';
import imageCoding from '../src/assets/images/2.png';
import imageCertified from '../src/assets/images/3.png';
import imageTeam from '../src/assets/images/4.png';
import imageCommunity from '../src/assets/images/4.png';

function App() {
  return (
    <>
      <Header />
      <div id="call-to-action">
        <WeekContent />
        <Newslater />
      </div>
      <Content 
        title={"O que eu vou aprender?"}
        image={imageCoding}
        alt={"Garota programando sentada."}
        paragraph=
          {
            "Neste BOOTCAMP terás o previlégio de aprender  muitos conceitos básicos que te darão uma base sólida que irão ajudar-te a enfrentar qualquer tipo de dificuldades como programador lá pra frente. No decorrer do bootcamp , aprenderás tantas coisa das quais podem ser:"
          } 
      />
      <Content  
        title={"Vou receber um certificado?"}
        image={imageCertified}
        alt={"Programador exibindo seu certificado."}
        paragraph={"A Octoplus acredita que os certificados não são as coisas que te tornam um programador melhor que os outros, o que faz a diferença é estudar e codificar constatemente. Tirar um tempo para manter as suas redes sociais ativa, postando os seus projetos, cursos, bootcamps que tens feito, isso te ajudará a ser encontrado por empresas pelas suas habilidades e não pelo certificado. porém contudo achamos que o Week4Saturno é mais que o um bootcamp, por isso terá certificados em formato digital. "} 
      />
      <Content
        title={"Comunidade"}
        image={imageCommunity}
        alt={"Comunidade unida."}
        paragraph={"Uma das formas que um programador se mantem atualizado e focado é através das comunidades, é compartilhando e adequirindo conhecimentos com outros programadores que você consegue evoluir ainda mais. Por isso a Octoplus está formando uma comunidade sólida de programadores de todos os níveis para partilha de conhecimentos e experiências de maneira amigável. E estaremos providenciando eventos presenciais para facilitar o networking com os programadores de maneira presencial."} 
      />
      <Content
        title={"O que vamos construir?"}
        image={imageProject}
        alt={"Construindo um projeto."}
        paragraph={"Durante o bootcamp a gente vai construir uma plataforma bastante importante e útil, que ajudará bastante você a usar a maior parte do seu conhecimento que vai aprender no bootcamp. A plataforma somente será divulgada no primeiro do evento, onde os futuros devs terão acesso ao cronograma e as telas que serão desenvolvidas."} 
      />
      <div className="article">
        <div className="avatar">
          <div id="image-avatar">
             <img src={imageCoding} height="220" width="224"/>
          </div>
          
          <p>C.E.O & Fundador da Octoplus</p>
          <b>Wilmy Danguya</b>
        </div>
        <div className="description trainer">
            <h1>Quem vai ensinar?</h1>
            <p>
            Programador acerca de 8 anos, apaixonado por tecnologias de informação. Ingressou na área de programação por causa do interesse sobre segurança de informação e Cybersecurity.
            Fundou a Octoplus com o objetivo de ajudar Angola a evoluir na área de programação ajudando os novos e futuros devs que estão entrando na área, através de cursos e treinamentos gratuitos.
            </p>
        </div>
      </div>
      <div className="article">
        <div className="hint">
          <p>
            Não existe o minuto
            esperado ou hora
            certa para começar 
            <b> apenas comece!</b>
          </p>
        </div>
        <Newslater />
      </div>
      <Footer />
      
      
    </>
  );
}

export default App;
