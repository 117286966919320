import './style.css'


function Header(){
    return(
        <div id="header-brand">
            <div >Octoplus</div>
            <menu id="header-menu">
                <ul>
                    <li><a href="#">Team</a></li>
                    <li><a href="#">Cursos</a></li>
                    <li><a href="#">Comunidade</a></li>
                    <li><a href="#">Contactos</a></li>
                </ul>
            </menu>
        </div>
    );
}

export default Header;