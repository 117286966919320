import './style.css';

function Content({title, paragraph, image, alt}){
    return(
        <div className="article">
            <div className="ilustration">
                <img alt={alt} height="400" width="400" src={image} />
            </div>
            <div className="description">
                <h1>{title}</h1>
                <p>
                    {paragraph}
                </p>
 
            </div>
        </div>
    );
}

export default Content;