import './style.css';


function Newslater(){
    return(
        <div id="newslater">

            <div className="input-container">
                <div  className="input-ico"></div>
                <input className="inputs" type="text" placeholder="Seu nome"/>
                <div id="input-background"></div>
            </div>
            <div className="input-container">
                <div  className="input-ico"></div>
                <input className="inputs" type="email" placeholder="E-mail"/>
                <div id="input-background"></div>
            </div>
            <input id="button_subscriber" type="submit" value="Participar do Bootcamp"/>
        </div>
    );
}

export default Newslater;